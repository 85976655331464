<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" class="login-box">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12 container-login">
              <v-toolbar color="rgb(246, 147, 30)" flat dark>
                <v-toolbar-title class="login-title">Login</v-toolbar-title>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :href="source"
                      icon
                      large
                      target="_blank"
                      v-on="on"
                    >
                      <v-icon>mdi-code-tags</v-icon>
                    </v-btn>
                  </template>
                  <span>Source</span>
                </v-tooltip> -->
                <!-- <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      large
                      href="https://codepen.io/johnjleider/pen/pMvGQO"
                      target="_blank"
                      v-on="on"
                    >
                      <v-icon>mdi-codepen</v-icon>
                    </v-btn>
                  </template>
                  <span>Codepen</span>
                </v-tooltip> -->
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    id="username"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                  />
                  <div class="form-message" id="logiResult"></div>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  center
                  class="login-btn"
                  dark
                  fab
                  color="rgb(246, 147, 30)"
                  @click="loginForm()"
                  ><v-icon>navigate_next</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  props: {
    source: String,
  },
  data() {
    return {
      user_id: "",
      name: "",
      email: "",
      username: "",
      user_level: "",
    };
  },
  methods: {
    loginForm: async function () {
      event.preventDefault();

      var campoResposta = document.querySelector("#logiResult");
      campoResposta.innerHTML = "A validar...";

      var username = document.querySelector("#username").value;
      var password = document.querySelector("#password").value;

      var dados = {
        username,
        password,
      };

      await this.$store.dispatch("login", dados);

      // var result = await this.doLogin(dados)
      var result = this.$store.state.resultApi;

      if (result.resultado == "OK") {
        this.user_id = result.userID;
        this.username = result.username;
        this.user_level = result.user_level;
        this.email = result.email;
        this.name = result.name;

        campoResposta.innerHTML = "Bem-vindo!";

        await this.setSession();

        let app = this;
        // setTimeout(function() {
        if (parseInt(this.user_level) > 1) {
          app.$router.push("/leads");
        } else {
          app.$router.push("/");
        }
        // }, 500)
      } else {
        campoResposta.innerHTML = "Utilizador ou password errados";
      }
    },
    async setSession() {
      window.sessionStorage.setItem("user_id", this.user_id);
      window.sessionStorage.setItem("username", this.username);
      window.sessionStorage.setItem("user_level", this.user_level);
      window.sessionStorage.setItem("email", this.email);
      window.sessionStorage.setItem("name", this.name);

      this.$emit("logged");

      return true;
    },
    doLogin: async function (apiBody) {
      try {
        var response = await fetch(
          "https://app.reorganiza.pt/public/api/login/",
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(apiBody, null, 2),
          }
        );
        var result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        return "Error: " + error;
      }
    },
  },
  mounted() {
    let app = this;
    document
      .querySelector("#username")
      .addEventListener("keypress", function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          app.loginForm();
        }
      });

    document
      .querySelector("#password")
      .addEventListener("keypress", function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          app.loginForm();
        }
      });

    let isLogged = window.sessionStorage.getItem("user_id");
    const result = isLogged == null ? false : true;

    if (result) {
      if (parseInt(window.sessionStorage.getItem("user_level")) > 1) {
        this.$router.push("/leads");
      } else {
        this.$router.push("/");
      }
    }
  },
  updated() {
    let isLogged = window.sessionStorage.getItem("user_id");
    const result = isLogged == null ? false : true;

    if (result) {
      if (parseInt(window.sessionStorage.getItem("user_level")) > 1) {
        this.$router.push("/leads");
      } else {
        this.$router.push("/");
      }
    }
  },
};
</script>


<style>
.login-btn {
  margin: 0 auto;
  width: 80px;
  height: 80px !important;
  margin-bottom: -100px;
  width: 80px !important;
}

.container-login {
  border-radius: 25px !important;
  padding-bottom: 50px;
}

.login-title {
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 900;
}

#inspire {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.login-box {
  margin-top: -200px;
}

.form-message {
  text-align: center;
}
</style>